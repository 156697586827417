import { environment } from '@environments/environment';
import { camelCaseToSentence } from '@helpers/string-manipulation';
import { HttpClientService } from '@services/http-client.service';

export class Logger {
  private _body: string | object;
  private _httpClientService: HttpClientService;

  constructor() {
    this._body = {};
    this._httpClientService = new HttpClientService();
  }

  public get body(): string | object {
    return this._body;
  }

  public set body(body: string | object) {
    this._body = body;
  }

  /**
   * Sends the error message to the configured Slack webhook.
   *
   * @returns A Promise that resolves when the Slack message has been sent.
   */
  public toSlack(): Promise<any> {
    return this._httpClientService
      .method('POST')
      .baseUrl(environment.WEB_HOOK)
      .body(JSON.stringify(this.body))
      .headers({})
      .response<any>();
  }

  /**
   * Builds an error message object with a header and detailed information about the error.
   *
   * @param error - An object containing error details.
   * @returns The current Logger instance with the error message set as the body.
   */
  public buildErrorMessage(
    error: Record<string, Record<string, string>>
  ): Logger {
    let message: Record<string, any> = {
      username: 'AngularError',
      icon_emoji: ':interrobang:',
      text: `${error['message']['content']}`,
      blocks: [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: `${error['message']['content']}`,
            emoji: true,
          },
        },
      ],
    };

    for (const [key, value] of Object.entries(error)) {
      let content = value['content'];

      if (key === 'stack') {
        content = `\`\`${content}\`\``;
      }

      message['blocks'].push(
        ...[
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*${value['emoji']} ${camelCaseToSentence(key)}:*`,
              },
              {
                type: 'mrkdwn',
                text: `\`${content}\``,
              },
            ],
          },
          {
            type: 'divider',
          },
        ]
      );
    }

    this.body = message;
    return this;
  }

  /**
   * Builds a debug message object with a header and a list of key-value pairs.
   * The message object is formatted for use in a Slack message.
   *
   * @param debug - An object containing the debug information to include in the message.
   * @param debug.header - The header text for the debug message.
   * @param debug[key] - Additional key-value pairs to include in the message.
   * @returns The Logger instance with the built debug message.
   */
  public buildDebugMessage(
    debug: Record<string, any>
  ): Logger {
    let message: Record<string, any> = {
      username: 'AngularDebug',
      icon_emoji: ':information_source:',
      text: `${debug['header']}`,
      blocks: [
        {
          type: 'header',
          text: {
            type: 'plain_text',
            text: `${debug['header']}`,
            emoji: true,
          },
        },
      ],
    };

    for (const [key, value] of Object.entries(debug)) {
      if (key === 'header') {
        continue;
      }

      message['blocks'].push(
        ...[
          {
            type: 'section',
            fields: [
              {
                type: 'mrkdwn',
                text: `*${camelCaseToSentence(key)}:*`,
              },
              {
                type: 'mrkdwn',
                text: `${value}`,
              },
            ],
          },
          {
            type: 'divider',
          },
        ]
      );
    }

    this.body = message;
    return this;
  }
}

export function capitalizeSentence(str: string): string {
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalized;
}

export function allCaps(str: string): string {
  return str.toUpperCase();
}

export function capitalizeWords(str: string): string {
  const words = str.split(' ');
  const capitalized = words.map((word) => capitalizeSentence(word));
  return capitalized.join(' ');
}

export function capitalizeHeadline(str: string): string {
  const words = str.split(' ');
  const nonCapitalized = [
    'a',
    'an',
    'the',
    'but',
    'or',
    'on',
    'in',
    'and',
    'as',
    'at',
    'of',
    'for',
    'by',
  ];

  words.forEach((word, index) => {
    if (nonCapitalized.includes(word) && index !== 0) {
      words[index] = word.toLowerCase();
    } else {
      words[index] = capitalizeSentence(word);
    }
  });

  return words.join(' ');
}

export function removeExtraSpaces(str: string): string {
  return str.trim().replace(/\s+/g, ' ');
}

export function kebabCase(str: string): string {
  return str
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('-');
}

export function snakeCase(str: string): string {
  return str
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
}

export function camelCase(str: string): string {
  const words = str.split(' ');
  const camel = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return capitalizeSentence(word);
  });
  return camel.join('');
}

export function pascalCase(str: string): string {
  const words = str.split(' ');
  const pascal = words.map((word) => capitalizeSentence(word));
  return pascal.join('');
}

export function shift(str: string): string {
  return str.slice(1) + str.charAt(0);
}

export function makeHashTag(str: string): string {
  const words = str.split(' ');
  const longest = words.sort((a, b) => b.length - a.length).slice(0, 3);
  const hashTags = longest.map((word) => `#${word.toLowerCase()}`);
  return hashTags.join(' ');
}

export function isEmpty(str: string): boolean {
  const breaks = ['\n', '\r', '\t'];
  const trimmed = breaks.includes(str)
    ? str.replace(/\n/g, '').trim()
    : str.trim();
  return trimmed.length === 0;
}

export function random(length: number): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}

export function camelCaseToSentence(str: string): string {
  // Replace camelCase with spaces before each uppercase letter
  const result = str.replace(/([A-Z])/g, ' $1');
  
  // Convert the entire string to lowercase
  const lowerCaseResult = result.toLowerCase();
  
  // Capitalize the first letter of the resulting string
  return lowerCaseResult.charAt(0).toUpperCase() + lowerCaseResult.slice(1);
}

import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private translate: TranslateService) {}

  /**
   * Retrieves the translated value for the given key and context, with optional parameters.
   *
   * @param value - The key or value to translate.
   * @param context - The context for the translation, defaults to an empty string.
   * @param params - Optional parameters to pass to the translation service.
   * @returns The translated value if found, otherwise the original value.
   */
  getInstant(value: string, context: string = '', params?: object): any {
    const key = context + '.' + value;

    if (!value) {
      return value;
    }

    // Fetch the translation
    const translatedValue = this.translate.instant(key, params);

    // Return the translation if found, otherwise return the original key
    return translatedValue === key ? value : translatedValue;
  }

  has(key: string): boolean {
    if (!key) {
      return false;
    }

    const translatedText = this.translate.instant(key);
    return translatedText !== key;
  }

  get(key: string, value: string, params?: Object): string {
    const termToTranslate = key.concat(...['.', value]);

    if (!value) {
      return '';
    }

    if (!this.has(termToTranslate)) {
      return value;
    }

    return this.translate.instant(termToTranslate, params);
  }

  getStream(key: string, params?: Object): Observable<string> {
    return this.translate.get(key, params);
  }

  validate(ngForm: NgForm): any {
    const errorMessages = {};

    Object.keys(ngForm.form.controls).forEach((controlKey) => {
      const control = ngForm.form.controls[controlKey];
      let errorKey = '';
      let params = {};
      let translatedControlName = '';
      let translatedMessage = '';

      if (control.errors) {
        errorKey = Object.keys(control.errors)[0];
        translatedMessage = errorKey;
        params = control.errors[errorKey];
        translatedControlName = this.get(controlKey, 'properties');
        params = { field: translatedControlName, ...params };
      }

      if (this.has('validation.' + errorKey)) {
        translatedMessage = this.get(errorKey, 'validation', params);
      }

      Object.defineProperty(errorMessages, controlKey, {
        value: translatedMessage,
        writable: false,
      });
    });

    return errorMessages;
  }
}

import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';
import { ClientError } from '@models/client-error';
import { ServerError } from '@models/server-error';
import { Logger } from '@models/logger';
import { MessageService } from '@services/message.service';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  private storageService: StorageService;
  private logger: Logger;

  constructor(private router: Router, private messageService: MessageService) {
    this.storageService = new StorageService();
    this.logger = new Logger();
  }

  public handleError(error: any) {
    let translationKey = '';
    let message = '';

    if (error instanceof ClientError) {
      translationKey = 'clientError';
      message = error.message;
      this.clientError(error);
    } else {
      message = error.data.message;
      this.serverError(error);
    }

    if (environment.production) {
      this.logger.buildErrorMessage(error.forSlack).toSlack();
    }

    this.messageService.translate(translationKey).error(message).showMessage();
  }

  private clientError(error: ClientError) {
    if (error.action === 'navigateToLogin') {
      this.router.navigateByUrl('/prijava');
    }
  }

  private serverError(error: ServerError) {
    let url = error.action;
    let rememberSession = false;

    switch (error.statusCode) {
      case 0:
        break;
      case HttpStatusCode.BadRequest:
        if (error.data.message === 'Unauthenticated.') {
          url = 'prijava';
          break;
        }
        break;

      case HttpStatusCode.Forbidden:
        break;

      case HttpStatusCode.Unauthorized:
        url = 'prijava';
        rememberSession = true;
        break;

      case HttpStatusCode.NotFound:
        if (error.data.message === 'Tenant not selected!') {
          url = 'evidencije';
          break;
        }
        break;

      case HttpStatusCode.Conflict:
        break;

      case HttpStatusCode.InternalServerError:
        url = 'noNavigation';
        break;

      default:
        break;
    }

    this.navigate(url, rememberSession);
  }

  private navigate(
    url: string = 'noNavigation',
    rememberSession: boolean = false
  ) {
    if (url === 'noNavigation') {
      return;
    }

    if (url === 'back') {
      if (window.history.length > 0) {
        window.history.back();
        return;
      }
      this.router.navigateByUrl('/');
      return;
    }

    if (rememberSession) {
      this.rememberSession();
    }

    this.router.navigateByUrl(url);
  }

  private rememberSession(): void {
    const tenant = this.storageService
      .get('activeTenant')
      .decrypt()
      .toJSON().value;
    const previousSession = {
      route: this.router.routerState.snapshot.url,
      tenantId: tenant['tenantData']['id'],
    };
    this.storageService
      .setValue(previousSession)
      .toString()
      .encrypt()
      .store('previousSession');
    this.storageService.remove('authToken');
    window.location.reload();
  }
}

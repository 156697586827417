import { Message } from 'primeng/api';

import { Alert as AlertInterface } from '../interfaces/alert';

export class Alert implements AlertInterface {
  private _detail: string;
  private _severtiy: string;
  private _summary: string;
  private _life: number;

  constructor(
    detail: string = '',
    severity: string = 'error',
    summary: string = '',
    life: number = 3000
  ) {
    this._detail = detail;
    this._severtiy = severity;
    this._summary = summary;
    this._life = life;
  }

  public get detail(): string {
    return this._detail;
  }

  public get severtiy(): string {
    return this._severtiy;
  }

  public get summary(): string {
    return this._summary;
  }

  public get life(): number {
    return this._life;
  }

  /* returns alert object of same structure as Message of p-toast component */
  public get toToastMessage(): Message {
    return {
      detail: this._detail,
      severity: this._severtiy,
      summary: this._summary,
      life: this._life,
    };
  }

  public set detail(detail: string) {
    this._detail = detail;
  }

  public set severity(severity: string) {
    this._severtiy = severity;
  }

  public set summary(summary: string) {
    this._severtiy = summary;
  }

  public set life(life: number) {
    this._life = life;
  }
}

import CryptoES from 'crypto-es';

import { environment } from '@environments/environment';

export class StorageService {
  value: any = null;

  has(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  setValue(value: any): StorageService {
    this.value = value;
    return this;
  }

  toString(): StorageService {
    this.value = JSON.stringify(this.value);
    return this;
  }

  toJSON(): StorageService {
    this.value = JSON.parse(this.value);
    return this;
  }

  store(name: string): StorageService {
    localStorage.setItem(name, this.value.toString());
    return this;
  }

  get(name: string): StorageService {
    this.value = localStorage.getItem(name);
    return this;
  }

  remove(name: string) {
    localStorage.removeItem(name);
  }

  clear() {
    localStorage.clear();
  }

  encrypt(): StorageService {
    this.value = CryptoES.AES.encrypt(
      this.value,
      environment.CRYPTOJS_SECRET_KEY
    );

    return this;
  }

  decrypt(): StorageService {
    this.value = CryptoES.AES.decrypt(
      this.value,
      environment.CRYPTOJS_SECRET_KEY
    ).toString(CryptoES.enc.Utf8);
    return this;
  }
}

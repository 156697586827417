import axios, { AxiosHeaders, AxiosRequestConfig, AxiosResponse } from 'axios';

import { environment } from '@environments/environment';
import { ServerError } from '@models/server-error';
import { ClientError } from '@models/client-error';
import { AuthToken } from '@authentication/authentication.interface';
import { StorageService } from '@services/storage.service';

export class HttpClientService {
  private _method: string = 'GET';
  private _baseUrl: string = environment.API_URL;
  private _route: string = '';
  private _body: string | object = {};
  private _headers: AxiosHeaders = new AxiosHeaders();
  private _params: any = {};
  private _action: string = 'noNavigation';
  private _storageService: StorageService = new StorageService();

  private resetProperties(): void {
    this._method = 'GET';
    this._baseUrl = environment.API_URL;
    this._route = '';
    this._body = {};
    this._headers = new AxiosHeaders();
    this._params = {};
    this._action = 'noNavigation';
  }

  private checkIsTokenExpired() {
    const authToken: AuthToken = this._storageService
      .get('authToken')
      .decrypt()
      .toJSON().value;

    if (authToken.expiresIn <= Date.now()) {
      throw new ClientError('tokenExpired', 'navigateToLogin');
    }
  }

  public method(method: string): HttpClientService {
    this.resetProperties();
    this._method = method;
    return this;
  }

  public baseUrl(baseUrl: string): HttpClientService {
    this._baseUrl = baseUrl;
    return this;
  }

  public route(route: string): HttpClientService {
    this._route = route;
    return this;
  }

  public body(body: string | object): HttpClientService {
    this._body = body;
    return this;
  }

  public headers(headers: {
    [header: string]: string | string[];
  }): HttpClientService {
    this._headers = new AxiosHeaders(headers);
    return this;
  }

  public params(params: {
    [param: string]: string | number | boolean;
  }): HttpClientService {
    this._params = params;
    return this;
  }

  public async response<T>(): Promise<T> {
    const fullUrl = this._baseUrl + this._route;

    const config: AxiosRequestConfig = {
      method: this._method,
      url: fullUrl,
      data: this._body,
      headers: this._headers,
      params: this._params,
    };

    try {
      const response: AxiosResponse<T> = await axios.request<T>(config);
      return response.data;
    } catch (error: any) {
      throw new ServerError(error, this._action);
    }
  }

  public withAuthToken(): HttpClientService {
    this.checkIsTokenExpired();

    const authToken: AuthToken = this._storageService
      .get('authToken')
      .decrypt()
      .toJSON().value;

    this._headers = new AxiosHeaders().set(
      'Authorization',
      `Bearer ${authToken.token}`,
      false
    );
    return this;
  }

  public onError(action: string): HttpClientService {
    this._action = action;
    return this;
  }
}

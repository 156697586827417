export class ClientError extends Error {
  public override message: string;
  public _action: string;

  constructor(message: string, action: string = 'noNavigation') {
    super();
    this.message = message;
    this._action = action;
  }

  get action(): string {
    return this._action;
  }

  get forSlack(): {} {
    return {
      message: {
        content: this.message,
        emoji: '🚨',
      },
    };
  }
}

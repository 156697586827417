import { AxiosError, AxiosResponse } from 'axios';

export class ServerError extends AxiosError {
  override message: string;
  override code?: string;
  override request?: any;
  override response?: AxiosResponse;
  private _status: number;
  private _action: string;
  private _data: any;

  constructor(error: any, action: string = 'noNavigation') {
    super();
    this.message = error.message;
    this.code = error.code;
    this.request = error.request;
    this.response = error.response;
    this._status = error.response?.status;
    this._action = action;
    this._data = error.response?.data;
  }

  get action(): string {
    return this._action;
  }

  get statusCode(): number {
    return this._status ?? this.status;
  }

  get data(): any {
    return this._data ?? { message: this.message };
  }

  get forSlack(): {} {
    return {
      message: {
        content: this.response?.data.message ?? this.message,
        emoji: '🚨',
      },
      url: { content: this.response?.config.url, emoji: '🔗' },
      status: { content: this.response?.status, emoji: '📋' },
      statusText: { content: this.response?.statusText, emoji: '📌' },
      method: { content: this.response?.config.method, emoji: '🛠️' },
      payload: { content: this.response?.config.data, emoji: '📦' },
      requestHeaders: {
        content: JSON.stringify(this.response?.config.headers),
        emoji: '📜',
      },
      params: {
        content: JSON.stringify(this.response?.config.params),
        emoji: '🧩',
      },
      responseHeaders: {
        content: JSON.stringify(this.response?.headers),
        emoji: '📥',
      },
      stack: { content: this.stack, emoji: '💥' },
    };
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Message } from 'primeng/api';

import { Alert } from '../models/alert';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  alerts: Array<Message> = [];
  alertObject: Observable<Array<Message>>;
  alertObjectEmmiter: BehaviorSubject<Array<Message>>;
  key: string = '';
  params: Object = {};

  constructor(private translationService: TranslationService) {
    this.alertObjectEmmiter = new BehaviorSubject<Array<Message>>([]);
    this.alertObject = this.alertObjectEmmiter.asObservable();
  }

  alert(severity: string, messages: Array<string> = [], life: number = 3000) {
    const summary = this.translationService.get('alert', severity);

    this.alerts = messages.map((message) => {
      message = this.translationService.get(this.key, message, this.params);
      return new Alert(message, severity, summary, life).toToastMessage;
    });
  }

  /**
   *  Build error message to show inside notification bubble
   *
   * @param messages Messages to show in component
   * @param life Number of milliseconds that message will be shown for
   * @returns MessageService
   */
  error(
    messages: string | Array<string> = [],
    life: number = 3000
  ): MessageService {
    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    this.alert('error', messages, life);

    return this;
  }

  /**
   *  Build warning message to show inside notification bubble
   *
   * @param messages Messages to show in component
   * @param life Number of milliseconds that message will be shown for
   * @returns MessageService
   */
  warning(
    messages: string | Array<string> = [],
    life: number = 3000
  ): MessageService {
    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    this.alert('warn', messages, life);

    return this;
  }

  /**
   *  Build success message to show inside notification bubble
   *
   * @param messages Messages to show in component
   * @param life Number of milliseconds that message will be shown for
   * @returns MessageService
   */
  success(
    messages: string | Array<string> = [],
    life: number = 3000
  ): MessageService {
    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    this.alert('success', messages, life);

    return this;
  }

  /**
   *  Build info message to show inside notification bubble
   *
   * @param messages Messages to show in component
   * @param life Number of milliseconds that message will be shown for
   * @returns MessageService
   */
  info(
    messages: string | Array<string> = [],
    life: number = 3000
  ): MessageService {
    if (!Array.isArray(messages)) {
      messages = [messages];
    }

    this.alert('info', messages, life);

    return this;
  }

  /**
   * Show message bubble
   *
   * @returns void
   */
  showMessage() {
    this.alertObjectEmmiter.next(this.alerts);
  }

  /**
   * Remove message bubble
   *
   * @returns MessageService
   */
  removeMessage(): MessageService {
    this.alertObjectEmmiter.next([]);
    return this;
  }

  /**
   * Translate message
   *
   * @returns MessageService
   */
  translate(key: string, value: string = '', params: Object = {}): MessageService {
    this.key = key;
    this.params = params;

    return this;
  }
}

export const environment = {
  production: true,
  ACCESS_TOKEN_MAPBOX:
    'pk.eyJ1Ijoic3dpbmctYWRtaW4iLCJhIjoiY2w4bGF1Y3A2MWUwdTN2bzUweDhuMDFmOSJ9.GMF4cMAhp5W64ADGd1fekw',
  API_URL: 'https://api.dev-tikitaki.swing-oblak.io',
  BBOX: '8.694131529577902,38.5548237230295,27.20271297148483,49.2314185107322',
  CRYPTOJS_SECRET_KEY: 'A42A23EBD68AB8D641CAA197',
  PROXIMITY: '16.439966,43.511638',
  TYPES: 'place,postcode,address',
  WEB_HOOK:
    'https://hooks.slack.com/services/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',
};
